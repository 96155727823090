<template>
  <li class="dropdown user-dropdown">
    <a href="#" class="dropdown-toggle mr-n1" data-toggle="dropdown">
      <div class="user-toggle">
        <div class="user-avatar sm">
          <img v-if="userData.dp" alt="DP" :src="userData.dp"/>
          <nio-icon v-else icon="ni-user-alt"></nio-icon>
        </div>
        <div class="user-info d-none d-xl-block">
          <!--<div class="user-status user-status-unverified">Unverified</div>-->
          <div class="user-name dropdown-indicator">{{ userData.first_name+' '+userData.last_name }}</div>
        </div>
      </div>
    </a>
    <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
      <div class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
        <div class="user-card">
          <div class="user-avatar">
            <img v-if="userData.dp" alt="DP" :src="userData.dp"/>
            <span v-else>{{ userData.initials }}</span>
          </div>
          <div class="user-info">
            <span class="lead-text">{{ userData.first_name+' '+userData.last_name }}</span>
            <span class="sub-text">{{ userData.email }}</span>
          </div>
        </div>
      </div>
      <div class="dropdown-inner">
        <ul class="link-list">
          <li><router-link :to="{name: userRole === 'is-advisor' ? 'Settings' : 'adminProfile'}">
            <nio-icon icon="ni-user-alt"></nio-icon>
            <span>View Profile</span>
          </router-link>
          </li>
          <li>
            <router-link :to="{name: userRole === 'is-advisor' ? 'Settings' : 'adminProfile'}">
              <nio-icon icon="ni-setting-alt"></nio-icon>
              <span>Account Setting</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: (userRole === 'is-advisor' ? 'Settings' : 'adminProfile'), params: {tab: 'activity'}}">
              <nio-icon icon="ni-activity-alt"></nio-icon>
              <span>Login Activity</span>
            </router-link>
          </li>
          <li>
            <a class="dark-switch clickable" href="javascript:;" :class="{active: darkMode}" v-on:click="toggleDarkMode">
              <nio-icon icon="ni-moon"></nio-icon>
              <span>Dark Mode</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="dropdown-inner">
        <ul class="link-list">
          <li>
            <a href="javascript:;" v-on:click="logout">
              <nio-icon icon="ni-signout"></nio-icon>
              <span>Sign out</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
import store from "@/store"
import {useRouter} from "vue-router"
import {computed, ref, toRef} from "vue";

export default {
  name: "UserDropdown",
  setup(){

    const router = useRouter()
    let userRole = toRef(store.state.auth, 'user_role')

    const userData = computed(()=>{
      return store.state.auth.user_role === 'is-advisor' ? store.state.advisor_data : store.state.admin_data
    })

    let darkMode = ref(store.state.appConfig.dark_mode)

    const logout = () => {
      store.dispatch('auth/auth_logout')
      .then(()=> {
        router.push({name: userRole.value === 'is-advisor' ? 'Login' : 'adminLogin'})
        setTimeout(()=> window.location.reload(), 200)
      })
    }

    const toggleDarkMode = () => {
      store.state.appConfig.dark_mode = !store.state.appConfig.dark_mode
      darkMode.value = store.state.appConfig.dark_mode
    }

    return {
      userData,
      logout,
      darkMode,
      toggleDarkMode,
      userRole,
    }
  }
}
</script>
