<template>
  <li class="nk-menu-item" :class="{active: (currentRoute === item.route)}">
    <router-link :to="{name: item.route}" class="nk-menu-link">
      <span class="nk-menu-icon" v-if="item.icon">
      <nio-icon :icon="item.icon"></nio-icon>
    </span>
      <span class="nk-menu-text">{{ item.title }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  //mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
    currentRoute: String,
  },
}
</script>
