<template>
  <div v-for="(n,index) in notifications.slice(0, limit)"
       :key="'dn'+index"
       v-on:click="[markAsRead(n), notificationAction(n)]"
       class="nk-notification-item dropdown-inner" :class="{'bg-lighter': !n.read_at}">
    <div class="nk-notification-icon">
      <nio-icon :icon="iconAndBGColor(n).icon" :class="iconAndBGColor(n).color" class="icon-circle"></nio-icon>
    </div>
    <div class="nk-notification-content">
      <div class="nk-notification-text" v-html="constructMessage(n)"></div>
      <div class="nk-notification-time">{{n.time}} ago</div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex"
import useNotifications from "@/views/notifications/useNotifications";

export default {
  name: "NotificationItems",
  props: {
    limit: {
      type: Number,
      default: 10,
    },
  },
  setup(){

    const store = useStore()
    const notifications = store.state.notifications.notifications

    const { constructMessage, iconAndBGColor, markAsRead, notificationAction } = useNotifications()

    return {
      constructMessage,
      iconAndBGColor,
      markAsRead,
      notifications,
      notificationAction,
    }
  }
}
</script>
