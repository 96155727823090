/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import store from "@/store"
import basic from './basic'
import admin from "./admin"
import {computed} from "vue";

// Array of sections
//export default store.state.auth.user_role === 'is-advisor' ? [...basic] : [...admin]
export default function (){

    const menuItems = computed(()=>{
        if(store.state.auth.user_role === false){
            return []
        }
        return store.state.auth.user_role === 'is-advisor' ? basic : admin
    })

    return {
        menuItems,
    }
}
