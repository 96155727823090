import {useRouter} from "vue-router";
import {useStore} from "vuex";

export default ()=>{

    const router = useRouter()
    const store  = useStore()

    const notificationTypes = {
        PasswordChanged: {
            icon: 'ni-lock-alt-fill',
            color: 'danger',
        }
    }
    const constructMessage = ( n ) => {

        let message = ''
        switch (n.type){
            case 'LeadAssigned':
                message = `You have assigned a new lead <b>#${ n.data.lead_number}</b>`
                break;
            case 'PasswordChanged':
                message = `Your password was changed <br/>at <b>${ n.data.time}, from IP ${ n.data.ip }</b>`
                break;
        }
        return message
    }
    const iconAndBGColor = ( n ) => {
        let data = {color: 'bg-success-dim', icon: 'ni-curve-down-right'}
        try {
            data.color = `bg-${notificationTypes[n.type].color}-dim`
            data.icon  = notificationTypes[n.type].icon
            return data
        }catch (e) {
            return data
        }
    }
    const notificationAction = ( n ) => {
        if(n.type === 'LeadAssigned'){
            router.push({name: 'LeadDetails', params:{ id: n.data.lead_id} })
        }
    }
    const markAsRead = (n) => {
        if(!n.read_at){
            store.dispatch('notifications/markNotificationAsRead', n.id)
            n.read_at = 'now'
            store.state.notifications.unread_count--;
        }
    }

    return {
        constructMessage,
        iconAndBGColor,
        markAsRead,
        notificationAction,
    }
}