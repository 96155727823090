<template>
  <div class="card" :class="cardClasses">
    <div class="card-inner-group" v-if="isGrouped">
      <slot name="grouped"></slot>
    </div>
    <div class="card-inner" :class="noPadding ? 'p-0' : ''">
      <slot></slot>
    </div>
  </div>
</template>

<script>

import {ref} from "vue";

export default {
  props: {
    fullHeight: {
      type: Boolean
    },
    noPadding: Boolean,
    isBordered: Boolean,
  },
  setup(props, {slots}){
    let cardClasses = ref([])
    if(props.isBordered){
      cardClasses.value.push('card-bordered')
    }
    if(props.fullHeight){
      cardClasses.value.push('card-full')
    }

    let isGrouped = slots.grouped && slots.grouped().length

    return {
      cardClasses,

      isGrouped,
    }
  }
}
</script>
