<template>
  <li class="nk-menu-item has-sub" :class="{active: isActive}" v-on:click="toggleMenuGroup($event)">
    <a href="javascript:;" class="nk-menu-link nk-menu-toggle">
      <span class="nk-menu-icon"><em class="icon ni ni-tile-thumb"></em></span>
      <span class="nk-menu-text">{{ $t(item.title) }}</span>
    </a>
    <ul class="nk-menu-sub">
      <component
          :is="resolveNavItemComponent(child)"
          v-for="child in item.children"
          :key="child.header || child.title"
          ref="groupChild"
          :item="child"
          :current-route="currentRoute"
      />
    </ul><!-- .nk-menu-sub -->
  </li>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '../utils'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import $ from "jquery"
import {computed, defineComponent} from "vue";
import {useStore} from "vuex"

export default defineComponent({
  name: 'VerticalNavMenuGroup',
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    currentRoute: String,
    groupId: String,
  },
  setup(props) {

    const store = useStore()
    const toggleMenuGroup = (e) => {

      const eTarget = e.target
      const currentTarget = e.currentTarget

      // If child link is clicked, then don't fold this menu
      if(eTarget.classList.contains('nk-menu-link') || eTarget.classList.contains('nk-menu-text')){

        let secondLevelParent = eTarget.parentElement.parentElement
        let thirdLevelParent  = secondLevelParent.parentElement

        if(eTarget.classList.contains('nk-menu-link') && secondLevelParent && secondLevelParent.classList.contains('nk-menu-sub')){
          return true;
        }
        else if(thirdLevelParent && thirdLevelParent.classList.contains('nk-menu-sub')){
          return true;
        }
      }

      // It was just a toggle
      if(e.currentTarget.classList.contains('active')){
        $(e.currentTarget.querySelector('.nk-menu-sub')).slideUp(400)
        setTimeout(()=>{
          currentTarget.classList.remove('active')
          store.commit('verticalMenu/SET_ACTIVE_GROUP', '')
        }, 400)
        return true
      }
      const items = document.getElementsByClassName('nk-menu-item has-sub')
      for(let x in items){
        if(!items[x].classList){
          continue
        }
        $(items[x].querySelector('.nk-menu-sub')).slideUp()
        items[x].classList.remove('active')
      }
      e.currentTarget.classList.add('active')
      $(e.currentTarget.querySelector('.nk-menu-sub')).slideDown(400)
      store.commit('verticalMenu/SET_ACTIVE_GROUP', props.groupId)
    }

    for(let x in props.item.children){
      if(props.item.children[x].route === props.currentRoute){
        store.commit('verticalMenu/SET_ACTIVE_GROUP', props.groupId)
        break
      }
    }

    const isActive = computed(()=> {
      return props.groupId === store.state.verticalMenu.activeGroup
    })

    return {
      isActive,
      resolveNavItemComponent,
      toggleMenuGroup,
    }
  },
})
</script>

<style>

</style>
