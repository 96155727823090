<template>
  <div class="nk-header is-light" :class="is_fixed ? 'nk-header-fixed':''">
    <div class="container-fluid">
      <div class="nk-header-wrap">
        <div class="nk-menu-trigger d-xl-none ml-n1">
          <a href="javascript:;" class="nk-nav-toggle nk-quick-nav-icon" v-on:click="toggleSidebar"><em class="icon ni ni-menu"></em></a>
        </div>
        <div class="nk-header-brand d-xl-none">
          <router-link :to="{name: 'Home'}" class="logo-link">
            <img class="logo-light logo-img" :src="appLogo" :srcset="require('@/assets/images/logos/logo2x.png')+' 2x'" alt="logo">
            <img class="logo-dark logo-img" :src="appLogoDark" :srcset="require('@/assets/images/logos/logo-dark2x.png')+' 2x'" alt="logo-dark">
          </router-link>
        </div><!-- .nk-header-brand -->
        <div class="nk-header-search ml-3 ml-xl-0">
          <nio-icon icon="ni-search"></nio-icon>
          <input type="text" class="form-control border-transparent form-focus-none" placeholder="Search anything">
        </div><!-- .nk-header-news -->
        <div class="nk-header-tools">
          <ul class="nk-quick-nav">
            <notification-dropdown></notification-dropdown>
            <user-dropdown></user-dropdown>
          </ul>
        </div>
      </div><!-- .nk-header-wrap -->
    </div><!-- .container-fliud -->
  </div>
</template>

<script>
import {$themeConfig} from "../../../../themeConfig";
import NotificationDropdown from "@core/layouts/app-nav-bar/NotificationDropdown";
import {nextTick, onMounted, ref, watch} from "vue";
const { appLogo, appLogoDark} = $themeConfig.app;
import {useStore} from "vuex"
import $ from "jquery";
import UserDropdown from "@core/layouts/app-nav-bar/UserDropdown";
import "bootstrap"

export default {
  name: "NavBar",
  components: {UserDropdown, NotificationDropdown},
  props:{
    is_fixed:{
      type: Boolean,
    },
  },
  setup() {

    const store = useStore()
    let sidebarActive = ref(false)

    watch(() => store.state.verticalMenu.toggleActive, (newV)=>{
      sidebarActive.value = newV
    })
    const toggleSidebar = () => {

      //menu.data("toggle-overlay") && (attr.overlay = attr.olay)
      //e && (attr.body = "toggle-shown")

      //attr.overlay && this.overlay(menu, attr.overlay, attr)

      sidebarActive.value = !sidebarActive.value
      store.commit('verticalMenu/UPDATE_VERTICAL_MENU_ACTIVE', sidebarActive.value)
      document.getElementsByTagName('body')[0].classList.toggle('nav-shown')
    }

    onMounted(()=>{
      nextTick(()=>{
        $(document).on('click', '.flatpickr-calendar', function (e) {
          e.stopPropagation();
        });
        let dd = '.dropdown-menu',
            ex = 'a:not(.clickable), button:not(.clickable), a:not(.clickable) *, button:not(.clickable) *';
        $(dd).on('click', function (e) {
          if (!$(e.target).is(ex)) {
            e.stopPropagation();
          }
        });
      })
    })


    return {
      appLogo,
      appLogoDark,
      toggleSidebar,
    }
  },
}
</script>
