<template>
  <ul class="nk-menu">
    <component
        v-for="item in items"
        :is="resolveVerticalNavMenuItemComponent(item)"
        :key="item.header || item.title"
        :item="item"
        :current-route="currentRoute"
        :group-id="item.group_id ? item.group_id: false"
        v-on:click="toggleMenuGroups(item)"
    />
  </ul>
</template>

<script>

import {resolveVerticalNavMenuItemComponent} from './utils'
import VerticalNavMenuHeader from './vertical-nav-menu-header/VerticalNavMenuHeader'
import VerticalNavMenuLink from './vertical-nav-menu-link/VerticalNavMenuLink'
import VerticalNavMenuGroup from './vertical-nav-menu-group/VerticalNavMenuGroup'
import {useRoute} from "vue-router"
import {useStore} from "vuex"

export default {
  components:{
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup(){

    const route = useRoute()
    let currentRoute = route.name

    const store = useStore()
    const toggleMenuGroups = (item) => {
      if(!item.children){
        store.commit('verticalMenu/SET_ACTIVE_GROUP','')
      }
    }

    return{
      resolveVerticalNavMenuItemComponent,
      currentRoute,
      toggleMenuGroups,
    }
  },
  created() {

  }
}
</script>
