export default [
  {
    header: 'Main Menu',
  },
  {
    title: 'Dashboards',
    icon: 'ni-dashboard',
    route: 'admin'
  },
  {
    title: 'Leads Management',
    icon: 'ni ni-list',
    group_id: 'leads_management',
    children: [
      {
        title: 'Leads List',
        route: 'adminLeadsList',
      },
      {
        title: 'Returned Leads',
        route: 'adminReturnedLeads',
      },
      {
        title: 'Assigned Leads',
        route: 'adminAssignedLeads',
      },
    ],
  },
  {
    title: 'Advisors',
    icon: 'ni ni-list-index',
    route: 'advisors',
  },
  {
    title: 'Marketplace',
    icon: 'ni ni-cart-fill',
    route: 'adminMarketplace',
  },
  {
    title: 'Settings',
    icon: 'ni ni-setting-alt',
    group_id: 'admin_settings',
    children: [
      {
        title: 'API Keys',
        route: 'adminAPIKeys',
      },
    ],
  },
]