export default [
  {
    header: 'Main Menu',
  },
  {
    title: 'Dashboards',
    icon: 'ni-dashboard',
    route: 'Home'
  },
  {
    title: 'Leads List',
    icon: 'ni ni-list-index',
    route: 'LeadsList',
  },
  {
    title: 'Calendar',
    icon: 'ni ni-calendar-alt',
    route: 'Calendar',
  },
  {
    title: 'Invoices',
    //icon: 'ni ni-report-profit',
    icon: 'ni ni-file-docs',
    route: 'Invoices',
  },
]
